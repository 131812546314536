import React from 'react'
import PropTypes from 'prop-types'
import HeaderNav from 'elements/Frame/HeaderNav'
import {
  Box
} from '@chakra-ui/core'

import Footer from 'elements/Footer/Footer'
import ElevationScroll from 'elements/Frame/ElevationScroll'

const HomeFrame = props => {
  const { children, ...rest } = props

  return (
    <>
      <ElevationScroll opacity={true} {...props}>
        <HeaderNav {...rest} />
      </ElevationScroll>

      <Box id="homeContainer">{children}</Box>
      <Footer />
    </>
  )
}

HomeFrame.propTypes = {
  children: PropTypes.node.isRequired
}

export default HomeFrame
