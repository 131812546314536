import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/core'
import { Link } from '@reach/router'
export default function BasicButton (props) {
  const { children, ...rest } = props

  const { variantColor } = rest
  const hover = variantColor === 'light' ? { bg: 'white', opacity: 0.9, color: 'secondary.300' } : {}
  return (
    <Button
      as={Link}
      fontSize="1em"
      variant="solid"
      _hover={hover}
      size="lg"
      {...rest}>
      {children}
    </Button>
  )
}

BasicButton.propTypes = {
  children: PropTypes.node
}
