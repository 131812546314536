import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Icon } from '@chakra-ui/core'

export default function Stamp ({ icon, ...rest }) {
  return (
    <Flex
      rounded="full"
      w="16"
      h="16"

      bg="primary.500"
      align="center"
      justify="center"
      {...rest}
    >
      <Icon fontSize="40px" color="white" name={icon} />
    </Flex>
  )
}

Stamp.propTypes = {
  icon: PropTypes.string.isRequired
}
