import React from 'react'
import PropTypes from 'prop-types'
import BasicButton from 'elements/Button/BasicButton'

export default function ScrollButton (props) {
  const { children, ...rest } = props
  return (
    <BasicButton to="false" {...rest} smooth={true}>
      {children}
    </BasicButton>
  )
}

ScrollButton.propTypes = {
  children: PropTypes.node
}
