import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/core'

export default function Big ({ children, ...rest }) {
  return <Heading my={1} fontWeight="light" as="h2" fontSize="40px" {...rest}>{children}</Heading>
}

Big.propTypes = {
  children: PropTypes.node
}
