import React, { useContext, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Big from 'elements/Typography/Big'
import BasicButton from 'elements/Button/BasicButton'
import Stamp from 'elements/Icon/Stamp'
import ScrollButton from 'elements/Link/ScrollButton'
import HomeFrame from 'elements/Frame/HomeFrame'
import LayoutContext from 'contexts/LayoutContext'
import PricingTable from 'elements/Plan/PricingTable'
import heroImage from 'images/landing/hero.png'
import dataImage from 'images/landing/automation.png'
import automationImage from 'images/landing/data.png'
import reactImage from 'images/landing/react.png'
import overlay from 'images/overlay.svg'
import { SUBSCRIBE_ROUTE } from 'config/routes'
import {
  Image,
  useTheme,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  TabList,
  SimpleGrid,
  Text,
  Flex,
  PseudoBox,
  Box,
  Heading
} from '@chakra-ui/core'

import { Element, scroller } from 'react-scroll'

const Feature = ({ title, icon, children, ...props }) => {
  return (
    <Box
      textAlign="center"
      p="40px"
      {...props}
    >
      <Stamp m="0 auto" icon={icon} />
      <Heading as="h3" size="md" fontWeight="semibold" mt="1em" mb="0.5em">
        {title}
      </Heading>
      <P fontSize="lg" opacity={0.7}>
        {children}
      </P>
    </Box>
  )
}
Feature.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

const Section = ({ children, align, title, header, icon, image, themed, ...rest }) => {
  return <Box
    position="relative"
    margin="0"
    padding={['0 40px', null, '0 100px']}
    border="0"
    display="flex"
    alignItems="center"
    // height="100vh"
    width="100%"
    overflow="hidden"
    backgroundImage={themed ? 'linear-gradient( to top, #31E8EF 0%, #3D1DAC 100%)' : ''}>
    <Flex
      m="50px auto"
      // backgroundImage={`url(${image})`}
      // backgroundSize="100% 50%"
      // backgroundPosition={`${align === 'left' ? 'right' : 'left'} bottom`}
      // backgroundRepeat="no-repeat"
      // textAlign={align}
      color={themed ? 'white' : 'gray.500'}
      width="100%"
      // minHeight="100vh"
      maxWidth={{ xs: '540px', sm: '720px', md: '960px', lg: '1140px' }}
      zIndex="2"
      flexDirection={[align === 'left' ? 'column-reverse' : 'column-reverse', null, align === 'left' ? 'row-reverse' : 'row']}
      alignItems={[align === 'left' ? 'flex-end' : 'flex-end', null, 'center']} justifyContent="center">
      <Image w={['100%', null, '50%']} src={image}/>
      <Box w={['100%', null, '50%']}>
        <Stamp mb={4} icon={icon}/>
        <Big>{title}</Big>
        <Big fontWeight="medium" color={themed ? 'white' : 'primary.500'}>{header}</Big>
        <P width="100%" color={themed ? 'white' : 'gray.500'}>{children}</P>
      </Box>

    </Flex>
    {/* <Flex
      m="50px auto"
      pb="300px"
      backgroundImage={`url(${image})`}
      backgroundSize="100% 50%"
      backgroundPosition={`${align === 'left' ? 'right' : 'left'} bottom`}
      backgroundRepeat="no-repeat"
      textAlign={align}
      color={themed ? 'white' : 'gray.500'}
      width="100%"
      // minHeight="100vh"
      maxWidth={{ xs: '540px', sm: '720px', md: '960px', lg: '1140px' }}
      zIndex="2"
      flexDirection="column" alignItems={align === 'left' ? 'flex-start' : 'flex-end'} justifyContent="center">

      <Stamp mb={4} icon={icon}/>
      <Big>{title}</Big>
      <Big fontWeight="medium" color={themed ? 'white' : 'primary.500'}>{header}</Big>
      <P width="100%" color={themed ? 'white' : 'gray.500'}>{children}</P>

    </Flex> */}
    {/* <Flex
      m="50px auto"
      backgroundImage={`url(${image})`}
      backgroundSize="50% auto"
      backgroundPosition={`${align === 'left' ? 'right' : 'left'} center`}
      backgroundRepeat="no-repeat"
      textAlign={align}
      color={themed ? 'white' : 'gray.500'}
      width="100%"
      // minHeight="100vh"
      maxWidth={{ xs: '540px', sm: '720px', md: '960px', lg: '1140px' }}
      zIndex="2"
      flexDirection="column" alignItems={align === 'left' ? 'flex-start' : 'flex-end'} justifyContent="center">

      <Stamp mb={4} icon={icon}/>
      <Big>{title}</Big>
      <Big fontWeight="medium" color={themed ? 'white' : 'primary.500'}>{header}</Big>
      <P width="50%" color={themed ? 'white' : 'gray.500'}>{children}</P>

    </Flex> */}
  </Box>
}

Section.defaultProps = {
  themed: false,
  align: 'left'
}
Section.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  align: PropTypes.string,
  themed: PropTypes.bool
}
const P = ({ children, ...rest }) => <Box fontSize="1.1em" fontWeight="medium" color="gray.400" {...rest}>{children}</Box>

P.propTypes = {
  children: PropTypes.node.isRequired
}

export default function LandingPage (props) {
  const theme = useTheme()

  const scrollTo = useCallback(
    (to, offset = 0) => {
      scroller.scrollTo(to, {
        duration: 500,
        delay: 100,
        smooth: true,
        // containerId: 'homeContainer',
        offset
      })
    },
    []
  )

  const { setTitle } = useContext(LayoutContext)
  useEffect(() => {
    setTitle('Home')
  }, [setTitle])

  // https://www.apollographql.com/
  return (
    <HomeFrame>
      <PseudoBox
        // height={["100vh"]}
        maxHeight="1600px"
        overflow="hidden"
        position="relative"
        margin="0"
        padding={['0 40px', null, '0 100px']}
        border="0"
        display="flex"
        alignItems="center"
        backgroundImage="linear-gradient( to top, #31E8EF 0%, #3D1DAC 100%)"
        _before={{
          content: '""',
          display: 'block',
          opacity: 0.06,
          backgroundImage: `url(${overlay})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          zIndex: '1',
          // width: '80%',
          // height: '80%',
          // left: '-100px',
          // top: '100px'
          width: '100%',
          height: '100%',
          left: '0',
          top: '0'
        }}
        _after= {{
          position: 'absolute',
          zIndex: '1',
          width: '100%',
          height: '100%',
          display: 'block',
          left: '0',
          top: '0',
          content: "''"
        }}

        // transform={transform}
        // Currently disabled as there is a flicker
      >

        <Flex
          m="50px auto"
          backgroundImage={['none', null, `url(${heroImage})`]}
          backgroundSize="60% auto"
          backgroundPosition="right center"
          backgroundRepeat="no-repeat"
          textAlign="left"
          color="white"
          width="100%"
          py="100px"
          maxWidth={{ xs: '540px', sm: '720px', md: '960px', lg: '1140px' }}
          zIndex="2"
          flexDirection="column" alignItems="left" justifyContent="center">

          <Heading maxWidth={['100%', null, '50%']} as="h1" size="xl">
                Meet the world&apos;s most powerful API connection hub.

          </Heading>
          <Heading maxWidth={['100%', null, '50%']} as="h4" size="sm"
            my={ 3}
          >
                With LotusEngine, connecting online tools for your business has never been easier.
          </Heading>

          <Box>
            <ScrollButton
              onClick={() => scrollTo('learnMore', 50)}
              fontWeight="700"
              mr={4}
              variant="outline"
              variantColor="light"
              size="lg"
            >Learn More</ScrollButton>
            <BasicButton
              my={4}
              variant="solid"
              variantColor="primary"
              to={SUBSCRIBE_ROUTE}
            >Get Started</BasicButton>
            {/* <ScrollButton
              onClick={() => scrollTo('getStarted')}
              fontWeight="700"
              mr={4}
              variant="solid"
              variantColor="primary"
              size="lg"
            >Get Started</ScrollButton> */}

          </Box>
        </Flex>
      </PseudoBox>
      <Flex
        width="80%"
        p="0"
        mx="auto"
        my={8}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        position="relative"
        zIndex="3"

      >
        <Element name="learnMore" />
        <Stamp my={8} icon="lotus"/>
        <Big> Automation. Storage. Analytics. Data Transfers. </Big>
        <Big fontWeight="medium" color="primary.500">All in one.</Big>
        <P maxWidth={['80%', null, '50%']}>LotusEngine lets you analyze streaming data, gain actionable insights, and respond customer needs in real time, all from one location. Our user-friendly dashboard enables you to connect, create and manage various services for your business, enhancing the efficiency and operation of your robust e-commerce platform.</P>
        {/* <Video/> */}

        <Tabs mt={8} variant="unstyled">
          <TabList w="80%" mx="auto" mb="1em">
            <Tab w="50%" fontSize="20px" color="gray.300" fontWeight="medium" _selected={{ color: 'black', borderBottom: `4px solid ${theme.colors.primary[500]}` }}>Developer Features</Tab>
            <Tab w="50%" fontSize="20px" color="gray.300" fontWeight="medium" _selected={{ color: 'black', borderBottom: `4px solid ${theme.colors.primary[500]}` }}>Marketer Features</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SimpleGrid columns={[1, null, 3]} spacing={4}>
                <Feature icon="gear" title="Quick Scaffolding">The quick scaffolding of apps increases the speed of development</Feature>
                <Feature icon="rocket" title="App Deployment">Conveniently deploy mini applications at the touch of a button.</Feature>
                <Feature icon="code" title="Custom Code">Stay flexible and expandable by implementing your own custom code.</Feature>
              </SimpleGrid>

            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={[2, null, 3]} spacing="40px">
                <Feature icon="tools" title="Complex Automations">Craft vendor agnostic automations of any complexity.</Feature>
                <Feature icon="users" title="Build for your Clients">Create specialized marketing services geared exactly meet your client&apos;s needs</Feature>
                <Feature icon="insight" title="Custom Analytics">Create the data reporting tool of your dreams.</Feature>
              </SimpleGrid>

            </TabPanel>
          </TabPanels>
        </Tabs>

        <BasicButton
          my={4}
          variant="solid"
          variantColor="primary"
          height="48px"
          width="200px"
          to={SUBSCRIBE_ROUTE}
        >Get Started</BasicButton>
      </Flex>
      <Section themed icon="insight" image={dataImage} title="Collect data" header="From anywhere">Go beyond the power of standard analytics. With LotusEngine, you have total control and flexibility when it comes to how you collect data, react to data, and deliver data. Welcome to a whole new world of possibilities.        </Section>

      <Section icon="clock" image={reactImage} align="right" title="React to events" header="In real time">One of LotusEngine’s key functions is its ability to react to events in real time. This event-based programming gives businesses a huge advantage by reacting to incoming data with lightning speed and unmatched precision. </Section>

      <Section themed icon="gear" image={automationImage} title="Respond with" header="Smart automations">LotusEngine lends itself perfectly to creating smart automations for your business. Respond to user actions by setting up mailing campaigns, deliver rich video content, and more! All of this can be done through our easy-to-set-up, completely customizable workflows. </Section>

      <Element name="getStarted" />
      <Box
        my="20"
        mx="auto"
        textAlign="center"
        position="relative"
        margin="0"
        padding={['0 40px', null, '0 100px']}
        alignItems="center"
        width="100%"
        maxWidth={{ xs: '540px', sm: '720px', md: '960px', lg: '1140px' }}>
        <Big label="Plans &amp; pricing">Something for everyone. <Text as="span" fontWeight="medium" color="primary.500">Seriously.</Text></Big>
        <P>Explore our plans and find the right fit for  your business.</P>
        <PricingTable/>
      </Box>

      <Box
        position="relative"
        margin="0"
        padding={['0 40px', null, '0 100px']}
        border="0"
        display="flex"
        alignItems="center"
        width="100%"
        overflow="hidden"
        backgroundImage="linear-gradient( to top, #31E8EF 0%, #3D1DAC 100%)">
        <Flex
          m="50px auto"
          color="white"
          width="100%"
          maxWidth={{ xs: '540px', sm: '720px', md: '960px', lg: '1140px' }}
          flexDirection={['column', 'row']} alignItems="center">

          <Box flex="1" textAlign="left">
            <Big color="white">Set up your account</Big>
            <Big color="white" fontWeight="medium">In just 2 minutes for free</Big>
          </Box>
          <Box>
            {/* <ScrollButton
              onClick={() => scrollTo('getStarted')}
              fontWeight="700"
              mr={4}
              variant="outline"
              variantColor="light"
              size="lg"
            >Get Started</ScrollButton> */}
            <BasicButton
              my={4}
              variant="outline"
              variantColor="light"

              size="lg"
              to={SUBSCRIBE_ROUTE}
            >Get Started</BasicButton>

          </Box>
        </Flex>
      </Box>

    </HomeFrame>
  )
}
